import { render, staticRenderFns } from "./Diagram.vue?vue&type=template&id=630cb3c2&scoped=true&"
import script from "./Diagram.vue?vue&type=script&lang=ts&"
export * from "./Diagram.vue?vue&type=script&lang=ts&"
import style0 from "./Diagram.vue?vue&type=style&index=0&id=630cb3c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630cb3c2",
  null
  
)

export default component.exports