











import router from "@/router";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ConfirmationCard extends Vue {
  public goTo() {
    router.push("/");
  }
}
