




























































































































































































import { Component, Vue } from "vue-property-decorator";
import GeneratorService from "@/services/GeneratorService";
import router from "@/router";
import _, { findKey } from "lodash";

@Component({
  components: {},
  props: ["admin"]
})
export default class Overview extends Vue {
  public loadingForCheckEmail = true;
  public isLoading: boolean = false;
  public isError: boolean = false;
  public errorMessage: string = "";
  public deleteMessage: string = "";
  public selectedFormatVersion: string = "";
  public selectedFormatVersionKey: string | undefined = "";
  public selectedFilter: string = "";
  public selectedFilterOption: string = "";
  public arrayFilter: string[] = ["Prüfende Rollen", "Prozessgruppen"];
  public formatVersions: any = {};
  public formatVersionsStrings: string[] = [];
  public filterOptions: string[] = [];
  public filter: string = "";
  public onlyNewFiles: boolean = false;
  public sortBy: string = "ebd_key";
  public sortDesc: boolean = false;
  public currentPage = 1;
  public totalRows = 1;
  public perPage = 10;
  public files: any[] = [];
  public filteredFiles: any[] = [];
  public roles: any[] = [];
  public groups: any[] = [];
  public fields: any[] = [
    { key: "icon", label: "", sortable: false },
    { key: "ebd_key", label: "EBD", sortable: true },
    { key: "title", label: "KAPITEL", sortable: true },
    { key: "title2", label: "UNTERKAPITEL", sortable: true },
    { key: "role", label: "ROLLE", sortable: true },
    { key: "openDiagram", label: "" },
    { key: "preview", label: "", requiresAdmin: true }
  ];

  public async deleteDiagram(item: any) {
    const deleteDiagram = await new GeneratorService().deleteDiagram(item.id);
    if (deleteDiagram) {
      const index = this.files.findIndex(file => {
        return file.id === item.id;
      });
      if (index) {
        this.files.splice(index, 1);
        this.filteredFiles = this.files;
      }
      this.deleteMessage = `${item.ebd_key} gelöscht.`;
    }
  }
  public openDiagramPage(ebdKey: string) {
    const routeData = this.$router.resolve({
      name: "Diagram",
      query: {
        ebdKey: ebdKey,
        formatVersion: this.selectedFormatVersionKey
      }
    });
    window.open(routeData.href, "_blank");
  }
  public async getFiles() {
    this.isError = false;
    this.files = [];
    this.isLoading = true;
    if (this.selectedFormatVersion.length !== 0) {
      this.selectedFormatVersionKey = _.findKey(
        this.formatVersions,
        key => key === this.selectedFormatVersion
      );
      this.files = await new GeneratorService().getFilesForOverview(
        this.selectedFormatVersionKey
      );
    }

    this.filteredFiles = this.files;
    if (this.filteredFiles) {
      this.isLoading = false;
      this.filteredFiles.forEach(file => {
        if (file.changed) {
          file.title = file.title + " 🆕";
        }
      });
    }
    this.totalRows = this.filteredFiles.length;
    this.selectedFilter = "";
    this.selectedFilterOption = "";
    this.onlyNewFiles = false;
    if (this.filteredFiles.length === 0) {
      this.isError = true;
      this.errorMessage = `Für Formatversion ${this.selectedFormatVersion} sind keine Diagramme vorhanden.`;
    }
  }
  public filterSelected() {
    this.selectedFilterOption = "";
    if (this.onlyNewFiles) {
      this.filteredFiles = this.files.filter(file => {
        return file.changed === true;
      });
    } else {
      this.filteredFiles = this.files;
    }
    if (this.selectedFilter === "Prüfende Rollen") {
      this.filterOptions = [
        ...new Set(this.filteredFiles.map(file => file.role))
      ];
    } else if (this.selectedFilter === "Prozessgruppen") {
      this.filterOptions = [
        ...new Set(this.filteredFiles.map(file => file.group))
      ];
    }
    this.totalRows = this.filteredFiles.length;
    this.currentPage = 1;
    this.filter = "";
  }
  public filteredByFilterOption() {
    if (this.onlyNewFiles) {
      this.filteredFiles = this.files.filter(file => {
        return file.changed === true;
      });
    } else {
      this.filteredFiles = this.files;
    }
    if (this.selectedFilter && this.selectedFilterOption) {
      if (this.selectedFilter === "Prüfende Rollen") {
        this.filteredFiles = this.filteredFiles.filter(file => {
          return file.role === this.selectedFilterOption;
        });
      } else if (this.selectedFilter === "Prozessgruppen") {
        this.filteredFiles = this.filteredFiles.filter(file => {
          return file.group === this.selectedFilterOption;
        });
      }
    }
    this.totalRows = this.filteredFiles.length;
    this.currentPage = 1;
    this.filter = "";
  }
  public onFiltered(filteredItems: any) {
    if (this.totalRows !== filteredItems.length) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
  public onFilteredOnlyNew() {
    if (this.onlyNewFiles) {
      this.filteredFiles = this.files.filter(file => {
        return file.changed === true;
      });
    } else {
      this.filteredFiles = this.files;
    }
    this.totalRows = this.filteredFiles.length;
    this.currentPage = 1;
    this.filter = "";
    this.selectedFilter = "";
    this.selectedFilterOption = "";
  }
  public async mounted() {
    // if userMail is in localStorage check this for authorization
    // if not userMail in localStorage got to login
    // for every error go to errorPage
    const userEmail: string | null = localStorage.getItem("userEmail");
    if (userEmail) {
      const userConfirmation = await new GeneratorService()
        .CheckUserConfirmation(userEmail)
        .catch(error => {
          this.loadingForCheckEmail = false;
          window.location.reload();
        });
      if (userConfirmation.status === 401) {
        router.push("/confirm-your-email");
      }
      if (userConfirmation.email) {
        localStorage.setItem("userRole", userConfirmation.role);
        this.loadingForCheckEmail = false;
      }
    } else {
      localStorage.clear();
      router.push("/login");
    }

    const userRole = localStorage.getItem("userRole");

    try {
      this.formatVersions = await new GeneratorService().getFormatVersions();
      // No formatVersion should be pre-selected said Thorsten
      this.formatVersionsStrings = Object.values(this.formatVersions);
    } catch (error) {
      this.isError = true;
      this.errorMessage = "Fehler beim Laden der Formatversionen.";
    }
    if (!this.$props.admin) {
      this.fields = this.fields.filter(field => !field.requiresAdmin);
    }
  }
}
