



































































































































import { Component, Vue } from "vue-property-decorator";
import GeneratorService from "@/services/GeneratorService";
import router from "@/router";

@Component({
  //   components: { Table }
})
export default class RegistrationComponent extends Vue {
  public isLoading: boolean = false;
  public callBackUserData = {};
  public isError: boolean = false;
  public isFieldEmpty: boolean = false;
  public isEmailValid: boolean = false;
  public saveUserData() {
    // check for required fields
    this.isLoading = true;
    localStorage.setItem("userForm", JSON.stringify(this.RegistrationData));
    const rolesArray = new GeneratorService()
      .postUserData(this.RegistrationData)
      .then(response => {
        this.isLoading = false;
        if (response.email) {
          localStorage.setItem("userEmail", this.RegistrationData.email.trim());
          localStorage.removeItem("userForm");
          router.push("/confirm-your-email");
        } else if (response.status === 401) {
          localStorage.setItem("userEmail", this.RegistrationData.email.trim());
          router.push("/confirm-your-email");
        } else if (response.status === 400) {
          this.isFieldEmpty = true;
        } else if (response.status === 404) {
          this.isEmailValid = true;
        } else if (response.status === 502 || response.status === 409) {
          this.isError = true;
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch(error => {
        this.isError = true;
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  }
  public RegistrationData = {
    firstName: "",
    lastName: "",
    company: "",
    jobDescription: "",
    email: "",
    privacyPermission: false
  };
  public mounted() {
    const userForm: any | null = localStorage.getItem("userForm");
    if (userForm) {
      this.RegistrationData = JSON.parse(userForm);
    }
  }
}
