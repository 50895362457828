






import { Component, Vue } from "vue-property-decorator";
import ConfirmationCard from "@/components/ConfirmationCard.vue";

@Component({
  components: {
    ConfirmationCard
  }
})
export default class EmailConfirmed extends Vue {}
