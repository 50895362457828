















































import { Component, Vue } from "vue-property-decorator";
import GeneratorService from "@/services/GeneratorService";

@Component({})
export default class Login extends Vue {
  public isLoading: boolean = false;
  public email = "";
  public errorMessage = "";

  public checkLogin() {
    if (this.email.length === 0) {
      this.errorMessage = "Geben Sie bitte Ihre E-Mail Adresse ein.";
    } else {
      this.isLoading = true;
      const userLogin = new GeneratorService().CheckUserConfirmation(
        this.email.trim()
      );
      userLogin
        .then(response => {
          this.isLoading = false;
          if (response.status === 502) {
            this.isLoading = false;
            this.errorMessage = `Es ist ein technisches Problem aufgetreten, bitte versuchen Sie es
           erneut' Sollte das Problem dauerhaft auftreten, kontaktieren Sie
        uns bitte.`;
          } else if (response.status === 400) {
            this.errorMessage =
              "Die eingegebene E-Mail-Adresse ist nicht registriert / bestätigt.";
          } else if (response.status === 401) {
            localStorage.setItem("userEmail", this.email);
            window.location.href = "/confirm-your-email";
          } else {
            localStorage.setItem("userRole", response.role);
            localStorage.setItem("userEmail", response.email);
            window.location.href = "/";
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.errorMessage =
            "Das hat nicht geklappt, bitte versuchen Sie es nach dem Reload erneut.";
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        });
    }
  }
}
