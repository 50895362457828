








import { Component, Vue } from "vue-property-decorator";
import RegistrationComponent from "@/components/RegistrationComponent.vue";
@Component({
  components: { RegistrationComponent }
})
export default class Register extends Vue {}
