








































































































































import { Component, Vue } from "vue-property-decorator";
import GeneratorService from "@/services/GeneratorService";
import XLSX from "xlsx";
import _, { findKey } from "lodash";

@Component({
  components: {}
})
export default class ExcelUpload extends Vue {
  public help: boolean = false;
  public upload: boolean = false;
  public loading: boolean = false;
  public document: any;
  public workbook: any;
  public worksheets: any[] = [];
  public data: any[] = [];
  public lineData: any[] = [];
  public postData: object = [];
  public formatVersions: any = [];
  public formatVersionsStrings: string[] = [];
  public selectedFormatVersion: string = "";
  public selectedFormatVersionKey: string | undefined = "";
  public selectedSheet: string = "";
  public isError: boolean = false;
  public errorMessage: string = "";
  public generatedDiagrams: any = [];
  public helpFields: any[] = ["Spaltenbezeichnung", "Bemerkung"];
  public helpItems: any[] = [
    { Spaltenbezeichnung: "EBD", Bemerkung: "Diagrammname" },
    {
      Spaltenbezeichnung: "Nr",
      Bemerkung:
        'Prüfschrittnummer "Zahl" bzw. Schrittbezeichnung "Titel", "Titel2", "0" (für Rolle)'
    },
    {
      Spaltenbezeichnung: "Prüfschritt",
      Bemerkung: "Text des Prüfschritts / Titels / Rolle / Hinweis"
    },
    {
      Spaltenbezeichnung: "Prüfergebnis",
      Bemerkung: 'Bei Prüfschritten: "ja"'
    },
    {
      Spaltenbezeichnung: "Folgeschritt_ja",
      Bemerkung:
        'nächster Prüfschritt, falls Antwort "ja" und nächster Prüfschritt existiert'
    },
    {
      Spaltenbezeichnung: "Code",
      Bemerkung: 'Antwortcode, bei Antwort "ja" und falls dieser existiert'
    },
    {
      Spaltenbezeichnung: "Hinweis_ja",
      Bemerkung:
        'Hinweis zu Prüfschritt, falls dieser für Antwort "ja" vorhanden sein sollmmname'
    },
    {
      Spaltenbezeichnung: "Prüfergebnis",
      Bemerkung: 'Bei Prüfschritten: "nein"'
    },
    {
      Spaltenbezeichnung: "Folgeschritt_nein",
      Bemerkung:
        'nächster Prüfschritt, falls Antwort "nein" und nächster Prüfschritt existiert'
    },
    {
      Spaltenbezeichnung: "Code",
      Bemerkung: 'Antwortcode, bei Antwort "ja" und falls dieser existiert'
    },
    {
      Spaltenbezeichnung: "Hinweis_nein",
      Bemerkung:
        'Hinweis zu Prüfschritt, falls dieser für Antwort "nein" vorhanden sein soll'
    }
  ];

  public restartThePage() {
    location.reload();
  }
  public async onChange(e: any) {
    this.isError = false;
    this.loading = false;
    this.document = await e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = e.target.result;
      const file = new Uint8Array(data);
      this.workbook = XLSX.read(file, { type: "array" });
      this.worksheets = this.workbook.SheetNames;
    };
    reader.readAsArrayBuffer(this.document);
    this.upload = true;
  }
  public async submitData() {
    this.loading = true;
    if (!this.document) {
      this.isError = true;
      this.errorMessage = "Failed.";
    } else {
      this.data = XLSX.utils.sheet_to_json(
        this.workbook.Sheets[this.selectedSheet],
        { header: 1 }
      );
      this.data.map((element: any) => {
        this.lineData.push({
          ebd_key: element[0] ? element[0] : null,
          stepNumber: element[1] ? element[1] : null,
          checkDescription: element[2] ? element[2] : null,
          answerTrue: {
            nextStep: element[4] ? element[4] : null,
            code: element[5] ? element[5] : null,
            note: element[6] ? element[6] : null
          },
          answerFalse: {
            nextStep: element[8] ? element[8] : null,
            code: element[9] ? element[9] : null,
            note: element[10] ? element[10] : null
          }
        });
      });
      this.selectedFormatVersionKey = _.findKey(
        this.formatVersions,
        key => key === this.selectedFormatVersion
      );
      this.postData = {
        sheet: this.selectedSheet,
        formatVersion: this.selectedFormatVersionKey,
        data: this.lineData
      };
      console.log(this.postData);
      this.generatedDiagrams = await new GeneratorService()
        .postExcelData(this.postData)
        .then((res: any) => {
          return res;
        })
        .catch((err: any) => {
          this.isError = true;
          this.errorMessage =
            "Ein Fehler ist aufgetreten, die Diagramme konnten nicht generiert werden.";
          console.log(this.isError);
          console.log(this.errorMessage);
        });
    }
    this.loading = false;
  }
  public async mounted() {
    try {
      this.formatVersions = await new GeneratorService().getFormatVersions();
      this.formatVersionsStrings = Object.values(this.formatVersions);
    } catch (error) {
      this.isError = true;
      this.errorMessage = "Fehler beim Laden der Formatversionen.";
    }
  }
}
