


























import router from "@/router";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ConfirmCard extends Vue {
  public displayedUserEmail: string = localStorage.getItem("userEmail") || "";
}
