




import { Component, Vue } from "vue-property-decorator";
import Overview from "./Overview.vue";

@Component({
  components: {
    Overview
  }
})
export default class Delete extends Vue {
  public admin: boolean = true;
}
