


































































import { Component, Vue } from "vue-property-decorator";
import GeneratorService from "@/services/GeneratorService";
import router from "@/router";
import Panzoom from "@panzoom/panzoom";
import { jsPDF } from "jspdf";
import "svg2pdf.js";

@Component({})
export default class Diagram extends Vue {
  public isLoading: boolean = false;
  public isError: boolean = false;
  public errorMessage: string = "";
  public ebdKey: any = "";
  public formatVersion: any = "";
  public stringlyFormatVersion: string | undefined = "";
  public diagram: any = "";
  public zoomObject: any = "";
  public svg: any = "";
  public zoomActive: boolean = false;

  public async downloadSVG(event: any, ebdKey: string) {
    const svgContent: any = document.getElementById(ebdKey)?.children[0]
        .outerHTML,
      blob = new Blob([svgContent], {
        type: "image/svg+xml"
      }),
      url = window.URL.createObjectURL(blob),
      link = event.target;
    link.target = "_blank";
    link.download = `${ebdKey}.svg`;
    link.href = url;
  }
  public async downloadPDF(event: any, ebdKey: string) {
    const svg: any = document.getElementById(ebdKey)?.children[0];

    // get width & height from svg
    const svgWidth: number = svg.getBoundingClientRect().width;
    const svgHeight: number = svg.getBoundingClientRect().height;

    let pdfOrientation: any = "portrait";
    if (svgWidth > svgHeight) {
      pdfOrientation = "landscape";
    }

    const pdfDoc: any = new jsPDF({
      orientation: pdfOrientation,
      unit: "px",
      format: "a4"
    });

    // get width/height of PDF
    const pdfWidth: number = pdfDoc.internal.pageSize.getWidth();
    const pdfHeight: number = pdfDoc.internal.pageSize.getHeight();

    // define svg image and set width/height to pdf page

    svg.setAttribute("width", `${pdfWidth}`);
    svg.setAttribute("height", `${pdfHeight}`);

    // get all text elements in svg and change the length x 0.75
    const textInPDF = svg.children[0].children[1].children[1].children;
    textInPDF.forEach((children: any) => {
      try {
        if (children.attributes["font-size"]) {
          const textLength: number = children.getAttribute("textLength");
          const newTextLength: number = textLength * 0.75;
          children.setAttribute("textLength", `${newTextLength}`);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    });
    // save pdf
    pdfDoc.svg(svg, 10, 0, pdfWidth, pdfHeight).then(() => {
      pdfDoc.save(`${ebdKey}.pdf`);
    });
    // set SVG back to normal size
    const reloadSVG: any = document.getElementById(ebdKey);
    reloadSVG.innerHTML = this.diagram;
  }
  public zoomActivation() {
    if (this.zoomActive == true) {
      this.svg = document.getElementById(`${this.ebdKey}`)?.children[0];
      this.zoomObject = Panzoom(this.svg, { maxScale: 5 });
      this.zoomObject.pan(10, 10);
      this.svg.parentElement.addEventListener(
        "wheel",
        this.zoomObject.zoomWithWheel
      );
    } else {
      this.svg.parentElement.removeEventListener(
        "wheel",
        this.zoomObject.zoomWithWheel
      );
      this.zoomObject = "";
    }
  }
  public async mounted() {
    if (!localStorage.getItem("userEmail")) {
      router.push("/login");
    }
    this.isLoading = true;
    this.ebdKey = this.$route.query.ebdKey;
    this.formatVersion = this.$route.query.formatVersion;
    try {
      const formatVersionArray = await new GeneratorService().getFormatVersions();
      this.stringlyFormatVersion = formatVersionArray[this.formatVersion];
    } catch (err) {
      this.isLoading = false;
      this.isError = true;
      this.errorMessage = `Unter der gewählten Formatversion ${this.formatVersion} wurde kein Diagramm gefunden.`;
    }
    try {
      this.diagram = await new GeneratorService().getDiagram(
        this.ebdKey,
        this.formatVersion
      );
      if (this.diagram.status === 400) {
        this.isError = true;
        this.errorMessage = `Der Entscheidungsbaum ${this.ebdKey} existiert für die Formatversion ${this.formatVersion} nicht.`;
      }
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      this.isError = true;
      this.errorMessage = `Beim Laden des Diagramms für den Entscheidungsbaum ${this.ebdKey} für die Formatversion ${this.formatVersion} ist leider ein Fehler aufgetreten.`;
    }
  }
}
