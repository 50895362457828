import { render, staticRenderFns } from "./ConfirmYourEmail.vue?vue&type=template&id=8b1e07a8&scoped=true&"
import script from "./ConfirmYourEmail.vue?vue&type=script&lang=ts&"
export * from "./ConfirmYourEmail.vue?vue&type=script&lang=ts&"
import style0 from "./ConfirmYourEmail.vue?vue&type=style&index=0&id=8b1e07a8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b1e07a8",
  null
  
)

export default component.exports